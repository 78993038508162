<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  // created(){
  //   this.$nextTick(() => {
  //       禁用右键
  //       document.oncontextmenu = new Function("event.returnValue=false");
  //       // 禁用选择
  //       document.onselectstart = new Function("event.returnValue=false");
  //     });
  // },
  mounted(){
    this.$forceUpdate()
  }
  
}
</script>
<style lang="scss">
.v-modal {
  z-index: 5 !important;
}
.el-header {
  margin: 20px 20px 0px 20px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 /11%) !important;
}
// .el-card {
//   margin: 20px;
// }
.el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 /11%) !important;
}
.el-aside {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 /11%) !important;
}
.overflow-hidden {
  overflow: visible;
}
.el-input__inner {
  border-radius: 5px !important;
}
.el-loading-mask {
  z-index: 4;
}
.el-button--info {
  color: #999 !important;
}
.el-button {
  border-radius: 5px !important;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #999 !important;
}
.searchFrom > .el-form-item {
  margin-right: 30px !important;
}
.el-select__tags-text {
  color: #999;
}

.el-menu--inline > .el-menu-item {
  transition: all 0 ease 0;
  padding-left: 75px !important;
}

.el-menu-item, .el-submenu__title {
  margin: 0 20px;
  border-radius: 5px;
  transition: none
}
.main-aside {
  width: 234px;
} 
.deletebutton {
  color: red !important;
}
.el-loading-mask {
  z-index: 4 !important;
}
.ql-editor {
  height: 200px !important;
}
.el-pagination {
  padding: 20px 0 !important;
}
.el-submenu [class^=el-icon-] {
  margin-right: 30px !important;
}
.el-icon-house, .el-icon-monitor {
  margin-right: 30px !important;
}
.is-active {
  .el-submenu__title {
    span {
      color: #5479FF !important;
    }
    i {
      color: #5479FF !important;
    }
  }
}
// .el-dialog {
//   background: #F7F8FF !important;
// }
.el-calendar-day {
  height: auto !important;
}
#addMore {
  .inner {
    max-height: 200px;
    overflow: auto;
    // &::-webkit-scrollbar{
    //   display: none;
    // }
  }
}
.el-dialog {
  background: #f7f8ff !important;
}
.el-dialog__wrapper {
  z-index: 111 !important;
}
.Selectnum{
  background-color: #F7F8FC;
}
.el-dialog__footer{
  text-align: center !important;
}
.el-dialog__close{
  font-size: 24px;
}
</style>
