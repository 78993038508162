import request from 'src/api/request'

//分页查
export const organList = data =>
  request({
    method: 'post',
    url: '/reporting/organization/pageList',
    data,
  })

//树结构
export const organTree = data =>
  request({
    method: 'post',
    url: '/reporting/organization/getOrganizationTreeList',
    data,
  })

//新增和保存
export const organSave = data =>
  request({
    method: 'post',
    url: '/reporting/organization/saveOrganization',
    data,
  })

//删除
export const organDel = data =>
  request({
    method: 'post',
    url: '/reporting/organization/deleteOrganization',
    data,
  })
