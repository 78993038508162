import request from 'src/api/request'
import requestPro from 'src/api/request2'

// //登录
// export const login = data =>
//   request({
//     method: 'post',
//     url: '/alanber_service/login/logIn',
//     data,
//   })
// //退出登录
// export const logout = () =>
//   request({
//     method: 'post',
//     url: '/reporting/login/signOut',
//   })

// //获取用户信息
// export const getUserInfo = () =>
//   request({
//     method: 'get',
//     url: '/alanber_service/login/userInfo',
//   })

// 获取所有机构列表 不分页
export const getAllInstitutionsInfo = data =>
  requestPro({
    method: 'post',
    url: '/company/queryCompany',
    data,
  })
// 机构设置 查询接口 分页
export const searchInstitutionsInfo = data =>
  requestPro({
    method: 'post',
    url: '/company/pageList/',
    data,
  })
// 机构设置 新增修改接口
export const saveOrganization = data =>
  requestPro({
    method: 'post',
    url: '/company/saveOrganization/',
    data,
  })

// 机构设置 删除
export const deleteOrganization = data =>
  requestPro({
    method: 'post',
    url: '/company/deleteOrganization',
    data,
  })

// post请求
export const postRequest = (url, data) =>
  requestPro({
    method: 'post',
    url,
    data,
  })
// 罗文的地址
export const postRequestAnthor = (url, data) =>
  request({
    method: 'post',
    url,
    data,
  })

export const postRequestDown = (url, params) =>
  requestPro({
    method: 'get',
    url,
    data: params,
    responseType: 'blob',
  })
