<template>
  <fragment>
    <template v-if="source.url">
      <el-menu-item :route="{ path: source.url }" @click="onRouterChange" :index="source.resourceId.toString()">
        <div>
          <i v-if="source.icon" :class="source.icon"></i>
          <span slot="title">{{ ($i18n.locale === 'en' ? source.resourceEn : source.resourceCh) || '未命名' }}</span>
        </div>
        
        <div class="big"><div class="small"></div></div>
      </el-menu-item>
    </template>
    <template v-else>
      <el-submenu :index="source.resourceId.toString()">
        <template slot="title">
          <i v-if="source.icon" :class="source.icon"></i>
          <span slot="title">{{ ($i18n.locale === 'en' ? source.resourceEn : source.resourceCh) || '未命名' }}</span>
        </template>
        <template v-if="source.children && source.children.length > 0">
          <main-menu-item v-for="node in source.children.filter(a => a)" :key="node.resourceId" :source="node">
          </main-menu-item>
        </template>
      </el-submenu>
    </template>
  </fragment>
</template>
<script>
export default {
  name: 'main-menu-item',
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onRouterChange() {
      // console.log(this.source);
      if (this.$route.fullPath === this.source.url) return
      this.$router.push(this.source.url)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-menu-item.is-active {
  color: #fff !important;
  // height: 100%;
  background: linear-gradient(to right, #5CC8FF , #5477FF) !important;
  overflow: hidden;
  position: relative;
  .big {
    width: 50px;
    background: #79CDFF;
    height: 50px;
    position: absolute;
    left: -25px;
    bottom: -25px;
    border-radius: 50%;
    .small {
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0px;
      background: #93D7FF;
      border-radius: 50%;
    }
  }
}

</style>
