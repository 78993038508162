<template>
  <el-menu
    :default-active="currentActive[currentActive.length - 1]"
    :default-openeds="currentActive"
    :collapse="isCollapse"
    class="main-menu"
    style="border-right: none"
    :unique-opened = uniqueFalg
  >
    <Logo></Logo>
    <main-menu-item v-for="n in navlist" :key="n.resourceId" :source="n"> </main-menu-item>
  </el-menu>
</template>

<script>
import { mapState } from 'vuex'
import MainMenuItem from './menuItem'
import Logo from 'src/components/logo'

export default {
  name: 'main-menu',
  components: {
    MainMenuItem,
    Logo,
  },
  props: {
    isCollapse: {
      type: Boolean,
    },
  },
  data() {
    return {
      powerlist: '',
      navlist: '',
      navchildrenlist:'',
      uniqueFalg:true
    }
  },
  computed: {
    ...mapState(['menusTree', 'rawMenus']),
    // 保持侧边栏高亮 返回一个ids 最后一个是高亮的目标，其他都是展开的ids
    currentActive() {
      // console.log(this.$route);
      const { path } = this.$route
      // console.log(this.rawMenus);
      const match = this.rawMenus.find(n => n.url === path)
      // console.log(this.rawMenus);
      if (match && match.parentResourceIds) {
        const opensIds = match.parentResourceIds.split(',')
        // 如果type 是menus 把自己的id也加上
        return match.resourceType === 'menu' ? opensIds.concat(`${match.resourceId}`) : opensIds
      } else if (match) {
        return [`${match.resourceId}`]
      }
      return []
    },
  },
  mounted() {
    // console.log(this.menusTree);
    // 拥有的主权限id
    this.powerlist = JSON.parse(localStorage.getItem('newToken'))
      .resources.map(obj => {
        if (obj.webUrl == null || obj.webUrl == '/dashboard' || obj.webUrl == '/workbench') {
          return obj.webId
        }
      })
      .filter(a => a)
    // console.log(this.powerlist)
    // 拥有的主权限对象
    this.navlist = this.menusTree
      // .map(item => {
      //   if (this.powerlist.includes(item.resourceId)) {
      //     return item
      //   }
      // })
      // .filter(a => a)
    // 拥有的所有权限
    // this.navchildrenlist = JSON.parse(localStorage.getItem('newToken')).resources.map(items=>items.webId)
    // // 筛选子权限
    // this.navlist.map(obj => {
    //   obj.children.map((objs, i) => {   
    //     if (!this.navchildrenlist.includes(objs.resourceId)) {
    //       obj.children.splice(i, 1,'')
    //     }
    //   })
    //   obj.children.filter(a => a)
    // })
  },
  methods: {},
}
</script>

<style>
.main-menu {
  width: 250px;
  overflow: hidden;
}
</style>
