/**
 * @file 请求封装
 * request({
 *   method: 'GET',
 *   url: 'xx',
 *   ignoreError: boolean, // 是否忽略掉网关层报错， 如果你这么做了，需要自己去catch该接口
 * })
 * */
import axios from 'axios'
import {
  Message
} from 'element-ui'

// 开发
// const createRequest = baseURL => {
  // const request = axios.create({
  //   baseURL,
  //   timeout: 60000,
  // })

  // 测试使用
  const createRequest = () => {
    const request = axios.create({
    // baseURL: '/api2',
    // baseURL: 'https://alanber.zhixunchelian.com/alanber_service/',
    baseURL: 'https://manage.alanberclinic.com/alanber_service/',
      timeout: 60000,
    })


  // 生产环境
  // const createRequest = () => {
  //   const request = axios.create({
  //   baseURL: 'https://manage.alanberclinic.com/alanber_service/',
  //     timeout: 60000,
  //   })

  // http request 拦截器 Request
  request.interceptors.request.use(
    config => {
      // 【鉴权】 从localStorage取出来，放在headers中
      const newToken = JSON.parse(localStorage.getItem('newToken'))
      if (newToken) {
        config.headers.computerToken = newToken.computerToken
      }
      const {
        method,
        data
      } = config
      // 所有请求只要走data
      if (method.toUpperCase() === 'GET') {
        config.params = data
        delete config.data
      }
      return config
    },
    error => {
      Promise.reject(error)
    },
  )

  // http response 拦截器 Response
  request.interceptors.response.use(
    response => {
      // console.log(response)
      const {
        data: res,
        status,
        config
      } = response
      if (status >= 200 && status < 300) {
        if (res.code != 200) {
          const message = res.msg
          // 业务报错
          if (!response.config.ignoreError) {
            Message({
              message,
              type: 'error',
              duration: 5000,
            })
          }
          return Promise.reject(res)
        } else {
          return res.data
        }
      } else {
        if (!config.ignoreError) {
          Message({
            message: '网络请求失败',
            type: 'error',
            duration: 5000,
          })
        }
        return Promise.reject(res)
      }
    }, error => {
      // 处理错误状态码 401， 403， 500等
      const response = error.response
      if (!response) {
        // 无返回结果为网络异常
        Message({
          message: '网络请求失败',
          type: 'error',
          duration: 5000,
        })

        return Promise.reject(error)
      }

      const message = response.data.msg || '请求错误'
      if (response.status === 401) {
        if (response.config && !response.config.ignoreError) {
          Message({
            message,
            type: 'error',
            duration: 5000,
          })
        }

        const location = window.location
        // 跳转登录页面
        location.href = '/login'
      } else if (response.config && !response.config.ignoreError) {
        Message({
          message,
          type: 'error',
          duration: 5000,
        })
      }
      return Promise.reject(error)
    },
  )

  return request
}

const request = createRequest(process.env.NODE_ENV !== 'production' ? '/api' : '')

export default request

export {
  createRequest
}
