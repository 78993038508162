<template>
  <el-container class="full-height-scroll">
    <!-- 侧边栏 -->
    <el-aside style="width: auto" class="color-white main-aside">
      <main-menu :isCollapse="isCollapse"></main-menu>
    </el-aside>
    <el-container class="overflow-auto">
      <!-- 头部 -->
      <el-header class="color-white main-header">
        <div class="flex hello">
          <i
            @click="onChangeMenuCollapse"
            :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            class="collapse-icon"
          ></i>
          <el-breadcrumb v-if="!($route.meta && $route.meta.noBreadCrumb)" class="mb-10" separator="/">
            <el-button class="info" @click="changePage">首页</el-button>
            <el-button class="button" :key="i" v-for="(node, i) in breadcurmb">
              <div>
                <router-link v-if="node.url" :to="node.url">{{ node.title }}</router-link>
                <span v-else>{{ node.title }}</span>
              </div>
              <!-- <i class="el-icon-close el-icon--rigth"></i> -->
            </el-button>
          </el-breadcrumb>
          <el-breadcrumb v-else class="mb-10" separator="/">
            <el-button class="info" @click="changePage">首页</el-button>
          </el-breadcrumb>
        </div>
        <div class="flex items-center">
          <i class="el-icon-bell" style="color: #5479ff"></i>
          <el-dropdown @command="onCommandTrigger" trigger="click">
            <span class="el-dropdown-link">
              <div class="user-block">
                <div class="user-block-name">
                  <span>{{ userdata?userdata.userInfo.nickname:"" }}</span>
                </div>
                <!-- <div class="user-block-avatar">
                  <img :src="require('/public/images/default_avatar.png')" alt="avatar" />
                </div> -->
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout">{{ $t('login.logout') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="flex flex-column">
        <!-- 内容导航 -->
        <!-- <el-breadcrumb v-if="!($route.meta && $route.meta.noBreadCrumb)" class="mb-10" separator="/">
          <el-breadcrumb-item :key="i" v-for="(node, i) in breadcurmb">
            <router-link v-if="node.url" :to="node.url">{{ node.title }}</router-link>
            <span v-else>{{ node.title }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb> -->
        <div class="flex-grow-1">
          <transition name="page-animation">
            <router-view class="color-white overflow-hidden"></router-view>
          </transition>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
/**
 * @file 主要布局
 */
import Logo from 'src/components/logo'
import MainMenu from './menu.vue'
import { setI18n } from 'src/language/index'
import { mapState } from 'vuex'
import { postRequestAnthor } from '@/api/index'

export default {
  name: 'main-layout',
  data() {
    return {
      languageOptions: [
        {
          label: 'English',
          value: 'en',
        },
        {
          label: '中文',
          value: 'zh',
        },
      ],
      isCollapse: false,
      guanjia: '管家',
      userdata: '',
    }
  },
  created() {
    this.userdata = JSON.parse(localStorage.getItem('newToken'))
  },
  methods: {
    // 【语言】 切换全局语言
    // onSwitchGlobalI18n(v) {
    //   this.$i18n.locale = v
    //   if (this.$route.meta?.title) {
    //     const title = this.$route.meta?.title
    //     document.title = title[v]
    //   }
    //   setI18n(v)
    // },
    changePage() {
      this.$router.push('/dashboard')
    },
    //退出登录
    logout() {
      if (localStorage.getItem('newToken')) {
        // this.userdata = JSON.parse(localStorage.getItem('newToken'))
        postRequestAnthor('/computer/login/signOut', {
          computerToken:  this.userdata?this.userdata.computerToken:'',
          phone:  this.userdata?this.userdata.userInfo.phone:"",
        })
          .then(res => {
            if (res == true) {
              localStorage.removeItem('newToken')
              this.$message({
                message: '已退出当前登录',
                type: 'success',
              })
              this.$router.push('/')
            }
          })
          .catch(() => {
            this.$message.error('退出登录失败')
          })
      }
    },
    onCommandTrigger(command) {
      switch (command) {
        case 'logout':
          this.logout()
          return
      }
    },
    onChangeMenuCollapse() {
      this.isCollapse = !this.isCollapse
    },
  },
  computed: {
    ...mapState(['menusTree', 'rawMenus', 'userInfo']),
    breadcurmb() {
      // console.log(this.$route, 'this.$route')
      const { path } = this.$route
      const match = this.rawMenus.find(n => n.url === path)
      const current = [{ title: (this.$i18n.locale === 'en' ? match.resourceEn : match.resourceCh) || '未命名' }]
      // if (match.parentResourceIds) {
      //   const ids = match.parentResourceIds.split(',')
      //   return ids
      //     .map(i => {
      //       const find = this.rawMenus.find(n => n.resourceId === +i)
      //       const ret = {
      //         title: (this.$i18n.locale === 'en' ? find.resourceEn : find.resourceCh) || '未命名',
      //       }
      //       if (find.url) ret.url = find.url
      //       return ret
      //     })
      //     .concat(current)
      // }
      return current
    },
  },
  mounted() {
    this.$forceUpdate()
  },
  components: {
    MainMenu,
  },
}
</script>
<style lang="scss" scoped>
.color-white {
  border-radius: 5px;
}
.main-header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  line-height: 30px;
  align-items: center;
  border-bottom: 1px solid rgb(238, 236, 236);
}
.user-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-left: 2px solid #f2f2fc;
  margin-left: 10px;
}
.user-block > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-block-name {
  margin: 0 10px;
  span {
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
  }
}
.user-block-avatar {
  width: 40px;
}
.user-block-avatar > img {
  width: 100%;
}

.collapse-icon {
  cursor: pointer;
  font-size: 20px;
  color: #5479ff;
}

.main-aside {
  border-right: solid 1px #e6e6e6;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  display: none;
}
.hello {
  display: flex;
  align-items: center;
}
h3 {
  margin-left: 5px;
}
.mb-10 {
  margin: 0 0 0 10px;
  span {
    font-size: 14px;
  }
}
.info {
  background: #ebecf2;
  color: #666;
  font-family: 'PingFangSC-Regular';
  font-size: 14px;
  padding: 9px 15px !important;
}
.button {
  background: #f2f3fa;
  color: #5479ff;
  font-size: 14px;
  span {
    display: block;
    display: flex !important;
  }
}
</style>
