/**
 * @file 中文文字包
 */

export default {
  //列表页查询form字段
  searchForm: {
    username: '用户名',
    usernamePlaceholder: '请输入用户名',
    organ: '机构名称',
    organPlaceholder: '请输入机构名称',
    organCode: '机构编码',
    organCodePlaceholder: '请输入机构编码',
    status: '状态',
    all: '全部',
    roleName: '角色名称',
    roleNamePlaceholder: '请输入角色名称',
    loginName: '登录名',
    loginNamePlaceholder: '请输入登录名',
    operator: '操作人',
    operatorPlaceholder: '请输入操作人',
    startTime: '开始时间',
    endTime: '结束时间',
    timePlaceholder: '请选择',
    time: '时间',
  },
  //列表页 表头
  table: {
    order: '序号',
    name: '姓名',
    account: '账号',
    operate: '操作',
    operator: '操作人',
    OperationMatters: '操作事项',
    OperatingTime: '操作时间',
    status: '状态',
    organ: '机构',
    organCode: '机构编码',
    parentOrganizationCode: '上级机构编码',
    parentOrgan: '上级机构',
    createBy: '创建人',
    createTime: '创建时间',
    roleName: '角色名称',
    remark: '备注',
    username: '用户名',
    loginName: '登录名',
    phone: '电话',
    operationDetails: '操作详情',
    taskName: '任务名称',
    deadline: '截止时间',
    dataType: '数据类型',
  },
  btn: {
    search: '查询',
    add: '新增',
    edit: '编辑',
    del: '删除',
    check: '查看',
    confirm: '确 定',
    cancel: '取 消',
    templateDownload: '模板下载',
    import: '导入',
    reimport: '重新导入',
  },
  //提示消息
  message: {
    add: '新增',
    edit: '编辑',
    saveSuccess: '保存成功',
    delSuccess: '删除成功',
    warning: '提示',
    delTip: '此操作将永久删除该数据, 是否继续?',
    disabledTip: '此操作将冻结该账户, 是否继续?',
  },
  enum: {
    status: {
      0: '无效',
      1: '有效',
    },
    accountStatus: {
      1: '正常',
      0: '冻结',
    },
    whether: {
      0: '否',
      1: '是',
    },
  },

  login: {
    account: '账户',
    accountMessage: '手机号不能为空',
    password: '密码',
    passwordMessage: '密码不能为空',
    remberPassword: '记住我',
    checkCode: '校验码',
    checkCodeMessage: '校验码不能为空',
    login: '登 录',
    loginStatus: '登 录 中ing',
    logout: '退出登录',
  },

  dialog: {
    //机构管理
    organManagement: {
      parentOrganCode: '上级机构',
      parentOrganCodeMsg: '请选择上级机构',
      organCode: '机构编码',
      organCodeMsg: '请输入机构编码',
      organName: '机构名称',
      organNameMsg: '请输入机构名称',
    },
    //角色管理
    roleManagement: {
      roleName: '角色名称',
      status: '状态',
      remark: '备注',
      BuiltInRole: '是否是内置角色',
      superAdminRole: '是否是超级管理员角色',
    },
    //用户管理
    userManagement: {
      userName: '用户名',
      userNameMsg: '请输入用户名',
      loginName: '登录名',
      loginNameMsg: '请输入登录名',
      loginPassword: '登录密码',
      loginPasswordMsg: '请输入登录密码',
      confirmPassword: '确认密码',
      confirmPasswordMsg: '请再次输入登录密码',
      confirmPasswordVerify: '两次输入的密码不一致',
      phone: '电话',
      phoneMsg: '请输入电话',
      userStatus: '状态',
      organization: '机构',
      organizationMsg: '请选择机构',
      role: '角色',
      roleMsg: '请选择角色',
    },
  },
}
