import Vue from 'vue'
import VueRouter from 'vue-router'
import { dynamicRoute, staticRoute } from './routes'
import { getI18n } from 'src/language/index'
import store from 'src/store/index'

import NProgress from 'nprogress' // progress bar

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: staticRoute,
})

router.beforeEach(async (to, from, next) => {
  try {
    NProgress.start()
    // 【设置title】
    // const { title, ignoreAuth } = to.meta || {}
    // if (title) {
    //   const language = getI18n()
    //   if (title[language]) {
    //     document.title = title[language]
    //   }
    // }
    // 修改前的 报错
    // if ( !ignoreAuth && !store.state.hasLogin) {
    // 修改后的 不报错
    if (!store.state.hasLogin) {
      // await store.dispatch('getUserInfo') // 触发getUserInfo
      // const rawDynamicMenus = await store.dispatch('getAllMenus')
      const data = [
        {
          "resourceId": 1,
          "resourceEn": "homePage",
          "resourceCh": "首页",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/dashboard",
          "icon": "el-icon-house",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 34,
          "resourceEn": "workbench",
          "resourceCh": "工作台",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/workbench",
          "icon": "el-icon-monitor",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 10,
          "resourceEn": "report manage",
          "resourceCh": "基础设置",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-setting",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 16,
          "resourceEn": "report manage",
          "resourceCh": "产品管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-menu",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 28,
          "resourceEn": "report manage",
          "resourceCh": "订单钱包",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-wallet",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 2,
          "resourceEn": "report manage",
          "resourceCh": "客户管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-user",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },

        // {
        //   "resourceId": 3,
        //   "resourceEn": "address",
        //   "resourceCh": "地址管理",
        //   "parentResourceId": 2,
        //   "resourceType": "menu",
        //   "resourceStatus": "1",
        //   "url": "/address",

        //   "resourceRemark": "",
        //   "createBy": "system",
        //   "createTime": "2021-07-09T08:00:07.000+0000",
        //   "updateBy": "system",
        //   "updateTime": "2021-07-09T08:00:07.000+0000",
        //   "parentResourceIds": "2"
        // },
        {
          "resourceId": 4,
          "resourceEn": "customerMatch",
          "resourceCh": "客户匹配",
          "parentResourceId": 3,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/customerMatch",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "2"
        },
        {
          "resourceId": 5,
          "resourceEn": "customersManagement",
          "resourceCh": "客户管理",
          "parentResourceId": 2,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/customersManagement",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "2"
        },
        {
          "resourceId": 19,
          "resourceEn": "report manage",
          "resourceCh": "任务处理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-document-checked",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 25,
          "resourceEn": "report manage",
          "resourceCh": "资讯管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-document",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 31,
          "resourceEn": "report manage",
          "resourceCh": "通知管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-bell",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        // {
        //   "resourceId": 6,
        //   "resourceEn": "customersPlan",
        //   "resourceCh": "客户计划配置",
        //   "parentResourceId": null,
        //   "resourceType": "menu",
        //   "resourceStatus": "1",
        //   "url": "/customersPlan",

        //   "resourceRemark": "",
        //   "createBy": "system",
        //   "createTime": "2021-07-09T08:00:07.000+0000",
        //   "updateBy": "system",
        //   "updateTime": "2021-07-09T08:00:07.000+0000",
        //   "parentResourceIds": "2"
        // },
        {
          "resourceId": 7,
          "resourceEn": "groupManagement",
          "resourceCh": "团队管理",
          "parentResourceId": 2,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/groupManagement",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "2"
        },
        // {
        //   "resourceId": 8,
        //   "resourceEn": "integralConfig",
        //   "resourceCh": "积分配置",
        //   "parentResourceId": 2,
        //   "resourceType": "menu",
        //   "resourceStatus": "1",
        //   "url": "/integralConfig",

        //   "resourceRemark": "",
        //   "createBy": "system",
        //   "createTime": "2021-07-09T08:00:07.000+0000",
        //   "updateBy": "system",
        //   "updateTime": "2021-07-09T08:00:07.000+0000",
        //   "parentResourceIds": "10"
        // },
        {
          "resourceId": 9,
          "resourceEn": "usersManagement",
          "resourceCh": "用户管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/usersManagement",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "2"
        },

        {
          "resourceId": 11,
          "resourceEn": "institutions",
          "resourceCh": "机构设置",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/institutions",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        {
          "resourceId": 49,
          "resourceEn": "reservationView",
          "resourceCh": "预约查询",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/reservationView",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        {
          "resourceId": 50,
          "resourceEn": "employeeProfile",
          "resourceCh": "员工简介",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/employeeProfile",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        {
          "resourceId": 12,
          "resourceEn": "basicInfo",
          "resourceCh": "基础信息维护",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/basicInfo",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        {
          "resourceId": 13,
          "resourceEn": "permissions",
          "resourceCh": "权限设置",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/permissions",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        {
          "resourceId": 14,
          "resourceEn": "staffRoster",
          "resourceCh": "员工花名册",
          "parentResourceId": "menu",
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/staffRoster",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        {
          "resourceId": 15,
          "resourceEn": "addOperator",
          "resourceCh": "添加操作员",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/addOperator",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },

        {
          "resourceId": 17,
          "resourceEn": "productList",
          "resourceCh": "产品列表",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/productList",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "16"
        },
        {
          "resourceId": 18,
          "resourceEn": "productCost",
          "resourceCh": "产品价格管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/productCost",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "16"
        },

        {
          "resourceId": 20,
          "resourceEn": "scheduling",
          "resourceCh": "排班管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/scheduling",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        {
          "resourceId": 21,
          "resourceEn": "serveScope",
          "resourceCh": "服务范围管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/serveScope",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "10"
        },
        // {
        //   "resourceId": 22,
        //   "resourceEn": "referral",
        //   "resourceCh": "转诊服务",
        //   "parentResourceId": null,
        //   "resourceType": "menu",
        //   "resourceStatus": "1",
        //   "url": "/referral",

        //   "resourceRemark": "",
        //   "createBy": "system",
        //   "createTime": "2021-07-09T08:00:07.000+0000",
        //   "updateBy": "system",
        //   "updateTime": "2021-07-09T08:00:07.000+0000",
        //   "parentResourceIds": "19"
        // },
        {
          "resourceId": 23,
          "resourceEn": "appointmentRegister",
          "resourceCh": "预约任务",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/appointmentRegister",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"
        },

        {
          "resourceId": 26,
          "resourceEn": "infomationrelease",
          "resourceCh": "资讯类型管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/infomationrelease",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "25"
        },
        {
          "resourceId": 27,
          "resourceEn": "infomationType",
          "resourceCh": "资讯文案发布",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/infomationType",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "25"
        },

        {
          "resourceId": 29,
          "resourceEn": "orderManagement",
          "resourceCh": "订单管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/orderManagement",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "28"
        },
        {
          "resourceId": 30,
          "resourceEn": "wallet",
          "resourceCh": "钱包充值",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/wallet",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "28"
        },

        {
          "resourceId": 32,
          "resourceEn": "model",
          "resourceCh": "通知模版",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/model",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "31"
        },
        {
          "resourceId": 33,
          "resourceEn": "noticeManagement",
          "resourceCh": "通知管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/noticeManagement",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "31"
        },
        {
          "resourceId": 35,
          "resourceEn": "teamConfig",
          "resourceCh": "团体权益配置",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/teamConfig",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "16"
        },
        {
          "resourceId": 36,
          "resourceEn": "formAndVideo",
          "resourceCh": "采样视频",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/formAndVideo",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "16"
        },
        {
          "resourceId": 37,
          "resourceEn": "theDoor",
          "resourceCh": "上门任务",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/theDoor",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"
        },
        {
          "resourceId": 38,
          "resourceEn": "mailTask",
          "resourceCh": "邮寄任务",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/mailTask",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"
        },
        // {
        //   "resourceId": 41,
        //   "resourceEn": "medicalExaminationTasks",
        //   "resourceCh": "体检任务",
        //   "parentResourceId": null,
        //   "resourceType": "menu",
        //   "resourceStatus": "1",
        //   "url": "/medicalExaminationTasks",

        //   "resourceRemark": "",
        //   "createBy": "system",
        //   "createTime": "2021-07-09T08:00:07.000+0000",
        //   "updateBy": "system",
        //   "updateTime": "2021-07-09T08:00:07.000+0000",
        //   "parentResourceIds": "19"
        // },
        {
          "resourceId": 39,
          "resourceEn": "visit",
          "resourceCh": "到访任务",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/visit",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"

        },
        {
          "resourceId": 24,
          "resourceEn": "projectManagement",
          "resourceCh": "方案管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/projectManagement",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"
        },
        {
          "resourceId": 46,
          "resourceEn": "miniProSentBack",
          "resourceCh": "小程序采样寄回",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/miniProSentBack",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"
        },
        {
          "resourceId": 47,
          "resourceEn": "reportInterpretation",
          "resourceCh": "报告解读/管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/reportInterpretation",

          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"
        },
        {
          "resourceId": 40,
          "resourceEn": "externalEquity",
          "resourceCh": "外部权益处理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/externalEquity",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "19"

        },
        // {
        //   "resourceId": 41,
        //   "resourceEn": "report manage",
        //   "resourceCh": "在线咨询",
        //   "parentResourceId": null,
        //   "resourceType": "menu",
        //   "resourceStatus": "1",
        //   "url": "",
        //   "icon": "el-icon-service",
        //   "resourceRemark": "",
        //   "createBy": "system",
        //   "createTime": "2021-07-09T08:00:07.000+0000",
        //   "updateBy": "system",
        //   "updateTime": "2021-07-09T08:00:07.000+0000",
        //   "parentResourceIds": null
        // },
        // {
        //   "resourceId": 42,
        //   "resourceEn": "online",
        //   "resourceCh": "在线咨询",
        //   "parentResourceId": null,
        //   "resourceType": "menu",
        //   "resourceStatus": "1",
        //   "url": "/online",
        //   "resourceRemark": "",
        //   "createBy": "system",
        //   "createTime": "2021-07-09T08:00:07.000+0000",
        //   "updateBy": "system",
        //   "updateTime": "2021-07-09T08:00:07.000+0000",
        //   "parentResourceIds": "41"

        // }
        {
          "resourceId": 43,
          "resourceEn": "question template",
          "resourceCh": "问卷管理",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "",
          "icon": "el-icon-document",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": null
        },
        {
          "resourceId": 44,
          "resourceEn": "questionTemplate",
          "resourceCh": "问卷模板",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/questionTemplate",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds": "43"
        },
        {
          "resourceId": 45,
          "resourceEn": "satisfactionquestion",
          "resourceCh": "满意度调查问卷",
          "parentResourceId": null,
          "resourceType": "menu",
          "resourceStatus": "1",
          "url": "/satisfactionquestion",
          "resourceRemark": "",
          "createBy": "system",
          "createTime": "2021-07-09T08:00:07.000+0000",
          "updateBy": "system",
          "updateTime": "2021-07-09T08:00:07.000+0000",
          "parentResourceIds":"43"
        },
      ]
      store.commit('changeHasLogin', true)
      await store.dispatch('initMenu',data)
      // 【动态路由注册】 根据接口返回的数据从dynamicRoute捞取对应的路由 并注册
      data.map(node => {
        const { url } = node
        if (!url) return
        const matchDynamicRoute = dynamicRoute.find(n => n.path === url)
        const comps = router.getMatchedComponents(url)
        // 如果路由匹配到了 并且没有重复注册 就添加路由
        if (matchDynamicRoute && comps.length === 0) {
          router.addRoute('main', matchDynamicRoute)
        }
      })
      // 重置路由
      return next({ ...to, replace: true })
    }
    next()
  } catch (e) {
    next(false)
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
