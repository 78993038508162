import request from 'src/api/request'

//分页查
export const roleList = data =>
  request({
    method: 'post',
    url: '/reporting/role/pageList',
    data,
  })

//查全部
export const roleAll = data =>
  request({
    method: 'post',
    url: '/reporting/role/getAllRole',
    data,
  })
