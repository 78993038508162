const getParentId = s => {
  if (!s) return s
  const arr = s.split(',')
  return arr[arr.length - 1]
}

export function makeTree(menus) {
  const bf = (arr, parentId) => {
    const collection = []
    for (const node of arr) {
      const nodeParentIds = node.parentResourceIds
      const nodeParentId = getParentId(nodeParentIds)
      if (+nodeParentId === +parentId) {
        collection.push({
          ...node,
          children: bf(arr, node.resourceId),
        })
      }
    }
    return collection
  }
  return bf(menus, null)
}
