/**
 * @file 语言包设置
 * 1. 组件语言包设置
 * 2. 自定义语言包
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import en from './en/index'
import zh from './zh/index'

Vue.use(VueI18n)

// 全局语言响应式切换 i18n.locale = xx
// 【语言】设置全局语言 默认中文 i18n
const i18n = new VueI18n({
  locale: localStorage.getItem('_language') || 'zh',
  fallbackLocale: 'zh',
  messages: {
    en: {
      ...enLocale,
      ...en,
    },
    zh: {
      ...zhLocale,
      ...zh,
    },
  },
})

i18n.subscribeDataChanging(args => {
  console.log(args)
})

export const setI18n = language => {
  localStorage.setItem('_language', language)
}

export const getI18n = () => {
  return localStorage.getItem('_language')
}

export default i18n
