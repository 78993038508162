<template>
  <router-link class="d-block logo-wrap" to="/dashboard">
    <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021091715232623557487.png" alt="logo" class="logo-open" />
  </router-link>
</template>

<script>
export default {
  name: 'logo',
}
</script>

<style lang="scss" scoped>
.logo-wrap {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0 14px;
  position: relative;
  left: 0;
  line-height: 40px;
  white-space: nowrap;
  text-align: center;
  height: 80px;
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .logo-open {
    vertical-align: middle;
    display: inline-block;
    width: 180px;
  }
  .logo-close {
    width: 40px;
  }
}
</style>
