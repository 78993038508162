import Vue from 'vue'
import Vuex from 'vuex'
import { login, menus_get, codeImg_get, getUserInfo, logout, getRequest } from 'src/api/index'
import { makeTree } from '../utils/makeTree'

/**
 * @typedef {object} menuItem
 * @property {string} url 侧边栏路由路径
 * @property {'menu'|'page'} type 如果是menu 会展示在侧边栏 如果是page会则代表这内页
 * @property {string} menuNameZh 中文名
 * @property {string} menuNameEn 英文目录名
 * */

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rawMenus: [],
    menusTree: [],
    rawPages: [],
    hasLogin: false,
    token: localStorage.getItem('newToken') || '',
    userInfo: {},
    userLoginForm: localStorage.getItem('bmw_yhx_loginForm') || {},
  },
  getters: {
    getUserLoginForm: state => {
      return JSON.parse(state.userLoginForm)
    },
  },
  mutations: {
    init(state, payload) {
      Object.assign(state, {
        rawMenus: payload.rawMenus,
        menusTree: payload.menusTree,
        rawPages: payload.rawPages,
      })
    },
    changeHasLogin(state, payload) {
      state.hasLogin = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload || {}
    },
    setToken(state, payload) {
      localStorage.setItem('newToken', payload || '')
      state.token = payload || ''
    },
    setLoginForm(state, payload) {
      localStorage.setItem('bmw_yhx_loginForm', payload || {})
      state.userLoginForm = payload || {}
    },
  },
  actions: {
    getAllMenus({ commit, dispatch }) {
      return menus_get().then(res => {
        dispatch('initMenu', res)
        return res
      })
    },
    initMenu({ commit }, menus) {
      commit('init', {
        rawMenus: menus,
        menusTree: makeTree(menus.filter(n => n.resourceType === 'menu')),
        rawPages: menus.filter(n => n.resourceType === 'page'),
      })
    },

    login({ commit }, userData) {
      let token = 'eyJhbGciOiJIUzUxMiJ9'
      commit('setToken', token)
      let res = {
        "userId": "htfndeupwychvacgsj",
        "userName": "admin",
        "loginName": "admin",
        "phone": "13488951320",
        "loginPassword": "123456",
        "userStatus": "1",
        "token": "eyJhbGciOiJIUzUxMiJ9"
      }
      return res
      // return login(userData).then(res => {
        
      //   return res
      // })
    },
    getCodeImg({ commit }) {
      return codeImg_get().then(res => {
        commit('setToken', res.token)
        return res.codeImage
      })
    },
    logout({ commit }) {
      return logout()
      // return Promise.resolve()
    },
    getUserInfo({ commit }) {
      return getUserInfo().then(res => {
        commit('setUserInfo', res)
      })
    },
  },
  modules: {},
})
