/**
 * @file 英文
 */

export default {
  //列表页查询form字段
  searchForm: {
    username: 'username',
    usernamePlaceholder: 'enter username',
    organ: 'name of organization',
    organPlaceholder: 'enter the name of the organization',
    organCode: 'code of organization',
    organCodePlaceholder: 'enter the code of the organization',
    status: 'status',
    all: 'all',
    roleName: 'role name',
    roleNamePlaceholder: 'please enter the role name',
    loginName: 'login name',
    loginNamePlaceholder: 'please enter the login name',
    operator: 'operator',
    operatorPlaceholder: 'please enter the operator',
    startTime: 'start time',
    endTime: 'end time',
    timePlaceholder: 'please choose a time',
    time: 'time',
  },
  //列表页 表头
  table: {
    order: 'Order',
    name: 'Name',
    account: 'Account',
    operate: 'operate',
    operator: 'Operator',
    OperationMatters: 'Operation Matters',
    OperatingTime: 'Operating Time',
    status: 'Status',
    organ: 'Organization',
    organCode: 'organization Code',
    parentOrganizationCode: 'Parent Organization Code',
    parentOrgan: 'Parent Organization',
    createBy: 'Creator',
    createTime: 'Creation Time',
    roleName: 'Role Name',
    remark: 'Remark',
    username: 'User Name',
    loginName: 'Login Name',
    phone: 'Phone',
    operationDetails: 'Operation Details',
    taskName: 'Task Name',
    deadline: 'Deadline',
    dataType: 'Data Type',
  },
  btn: {
    search: 'search',
    add: 'add',
    edit: 'edit',
    del: 'delete',
    check: 'check',
    confirm: 'confirm',
    cancel: 'cancel',
    templateDownload: 'template download',
    import: 'import',
    reimport: 'reimport',
  },

  //提示消息
  message: {
    add: 'Add',
    edit: 'Edit',
    saveSuccess: 'Saved successfully',
    delSuccess: 'successfully deleted',
    warning: 'Warning',
    delTip: 'This operation will permanently delete the data, whether to continue?',
    disabledTip: 'This operation will freeze the account, whether to continue?',
  },

  enum: {
    status: {
      0: 'disabled',
      1: 'effective',
    },
    accountStatus: {
      1: 'normal',
      0: 'freeze',
    },
    whether: {
      0: 'no',
      1: 'yes',
    },
  },

  //登录
  login: {
    account: 'Account',
    accountMessage: 'Account cannot be empty',
    password: 'Password',
    passwordMessage: 'Password cannot be empty',
    remberPassword: 'Remember me',
    checkCode: 'Check Code',
    checkCodeMessage: 'The check code cannot be empty',
    login: 'Login',
    loginStatus: 'logging in',
    logout: 'log out',
  },

  dialog: {
    //机构管理
    organManagement: {
      parentOrganCode: 'Parent Organization',
      parentOrganCodeMsg: 'Please select a parent organization',
      organCode: 'Organization Code',
      organCodeMsg: 'Please enter the organization code',
      organName: 'Organization Name',
      organNameMsg: 'Please enter the name of the organization',
    },
    //角色管理
    roleManagement: {
      roleName: 'Name of role',
      status: 'Status',
      remark: 'Remark',
      BuiltInRole: 'Is  a built-in role',
      superAdminRole: 'Is a super administrator role',
    },

    //用户管理
    userManagement: {
      userName: 'User Name',
      userNameMsg: 'Please enter user name',
      loginName: 'Login Name',
      loginNameMsg: 'Please enter login name',
      loginPassword: 'Login Password',
      loginPasswordMsg: 'Please enter password',
      confirmPassword: 'Confirm password',
      confirmPasswordMsg: 'Please enter the password again',
      confirmPasswordVerify: 'The two passwords did not match.',
      phone: 'Phone',
      phoneMsg: 'Please enter phone number',
      userStatus: 'Status',
      organization: 'Organization',
      organizationMsg: 'Please select organizations',
      role: 'Role',
      roleMsg: 'Please select roles',
    },
  },
}
