import request from 'src/api/request'

//分页查
export const userList = data =>
  request({
    method: 'post',
    url: '/reporting/user/pageList',
    data,
  })
//保存和修改
export const userSave = data =>
  request({
    method: 'post',
    url: '/reporting/user/saveUser',
    data,
  })

//更新用户状态
export const userStatusUpdate = data =>
  request({
    method: 'post',
    url: '/reporting/user/updateUserStatus',
    data,
  })

//更改密码
export const userPwdChange = data =>
  request({
    method: 'post',
    url: '/reporting/user/updateLoginPassword',
    data,
  })

//用户详情
export const userDetail = data =>
  request({
    method: 'post',
    url: '/reporting/user/getUserInfo',
    data,
  })
