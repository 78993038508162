/**
 * @file 路由页面
 * */
import MainLayout from 'src/components/layouts/main'

/**
 * @global
 * @typedef {Object} meta
 * @property {string} title.en 英文title
 * @property {string} title.zh 中文title
 * @property {boolean} ignoreAuth 是否忽略登录，如果是则不会动态注册路由
 * @property {boolean} noBreadCrumb 是否忽略面包屑路由路径
 * */

/**
 * @global
 * @typedef {Object} RouteItem
 * @property {string} path
 * @property {Object} component
 * @property {meta} meta
 * @property {RouteItem[]} children
 * */

/**
 * @type {RouteItem[]}
 * */
export const staticRoute = [
  {
    path: '/',
    redirect: {
      path: '/login'
    },
  },
  {
    path: '/login',
    component: () => import('src/views/login/index'),
    meta: {
      title: {
        en: 'login',
        zh: '登录',
      },
      ignoreAuth: true,
    },
  },
  {
    path: '',
    name: 'main',
    component: MainLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('src/views/dashboard/index'),
        meta: {
          title: {
            en: 'homepage',
            zh: '首页',
          },
          noBreadCrumb: true,
        },
      },
    ],
  },
]

/**
 * 动态路由池，这里面的路由需要根据后端返回的路由去匹配到才能渲染
 * @type {RouteItem[]}
 * */
export const dynamicRoute = [

  {
    path: '/report_list',
    name: 'report_list',
    meta: {
      title: {
        zh: '报表列表',
        en: 'report list',
      },
    },
    component: () => import('src/views/report/list/index'),
  },
  {
    path: '/report_detail',
    name: 'report_detail',
    meta: {
      title: {
        zh: '报表详情',
        en: 'report detail',
      },
    },
    component: () => import('src/views/report/detail/index'),
  },
  {
    path: '/report_import',
    name: 'report_import',
    meta: {
      title: {
        zh: '数据导入',
        en: 'report import',
      },
    },
    component: () => import('src/views/report/import/index'),
  },
  {
    path: '/authority_organmanagement',
    name: 'authority_organmanagement',
    meta: {
      title: {
        zh: '机构管理',
        en: 'organ management',
      },
    },
    component: () => import('src/views/authority/organManagement/index'),
  },
  {
    path: '/authority_rolemanagement',
    name: 'authority_rolemanagement',
    meta: {
      title: {
        zh: '角色管理',
        en: 'role management',
      },
    },
    component: () => import('src/views/authority/roleManagement/index'),
  },
  {
    path: '/authority_usermanagement',
    name: 'authority_usermanagement',
    meta: {
      title: {
        zh: '用户管理',
        en: 'user management',
      },
    },
    component: () => import('src/views/authority/userManagement/index'),
  },
  {
    path: '/operation_log',
    name: 'operation_log',
    meta: {
      title: {
        zh: '日志管理',
        en: 'log management',
      },
    },
    component: () => import('src/views/operation/log/index'),
  },
  {
    path: '/workbench',
    name: 'workbench',
    meta: {
      title: {
        zh: '工作台',
        en: 'workbench',
      },
    },
    component: () => import('src/views/workbench/index'),
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      title: {
        zh: '地址管理',
        en: 'address',
      },
    },
    component: () => import('src/views/customerManagement/address'),
  },
  {
    path: '/customerMatch',
    name: 'customerMatch',
    meta: {
      title: {
        zh: '客户匹配',
        en: 'customerMatch',
      },
    },
    component: () => import('src/views/customerManagement/customerMatch'),
  },
  {
    path: '/teamConfig',
    name: 'teamConfig',
    meta: {
      title: {
        zh: '团体权益配置',
        en: 'teamConfig',
      },
    },
    component: () => import('src/views/productManagement/teamConfig'),
  },
  {

    path: '/reservationView',
    name: 'reservationView',
    meta: {
      title: {
        zh: '预约查询',
        en: 'reservationView',
      },
    },
    component: () => import('src/views/basisSet/reservationView'),
  },
  {

    path: '/reportInterpretation',
    name: 'reportInterpretation',
    meta: {
      title: {
        zh: '报告解读/管理',
        en: 'reportInterpretation',
      },
    },
    component: () => import('src/views/appointmentManagement/reportInterpretation'),
  },
  {

    path: '/employeeProfile',
    name: 'employeeProfile',
    meta: {
      title: {
        zh: '员工简介',
        en: 'employeeProfile',
      },
    },
    component: () => import('src/views/basisSet/employeeProfile'),
  },
  {
    path: '/customersManagement',
    name: 'customersManagement',
    meta: {
      title: {
        zh: '客户管理',
        en: 'customersManagement',
      },
    },
    component: () => import('src/views/customerManagement/customersManagement'),
  },
  {
    path: '/customersPlan',
    name: 'customersPlan',
    meta: {
      title: {
        zh: '客户计划配置',
        en: 'customersPlan',
      },
    },
    component: () => import('src/views/customerManagement/customersPlan'),
  },
  {
    path: '/groupManagement',
    name: 'groupManagement',
    meta: {
      title: {
        zh: '团队管理',
        en: 'groupManagement',
      },
    },
    component: () => import('src/views/customerManagement/groupManagement'),
  },
  {
    path: '/integralConfig',
    name: 'integralConfig',
    meta: {
      title: {
        zh: '积分配置',
        en: 'integralConfig',
      },
    },
    component: () => import('src/views/customerManagement/integralConfig'),
  },
  {
    path: '/usersManagement',
    name: 'usersManagement',
    meta: {
      title: {
        zh: '用户管理',
        en: 'usersManagement',
      },
    },
    component: () => import('src/views/customerManagement/usersManagement'),
  },
  {
    path: '/institutions',
    name: 'institutions',
    meta: {
      title: {
        zh: '机构设置',
        en: 'institutions',
      },
    },
    component: () => import('src/views/basisSet/institutions'),
  },
  {
    path: '/basicInfo',
    name: 'basicInfo',
    meta: {
      title: {
        zh: '基础信息维护',
        en: 'basicInfo',
      },
    },
    component: () => import('src/views/basisSet/basicInfo'),
  },
  {
    path: '/permissions',
    name: 'permissions',
    meta: {
      title: {
        zh: '权限设置',
        en: 'permissions',
      },
    },
    component: () => import('src/views/basisSet/permissions'),
  },
  {
    path: '/staffRoster',
    name: 'staffRoster',
    meta: {
      title: {
        zh: '员工花名册',
        en: 'staffRoster',
      },
    },
    component: () => import('src/views/basisSet/staffRoster'),
  },
  {
    path: '/addOperator',
    name: 'addOperator',
    meta: {
      title: {
        zh: '添加操作员',
        en: 'addOperator',
      },
    },
    component: () => import('src/views/basisSet/addOperator'),
  },
  {
    path: '/productCost',
    name: 'productCost',
    meta: {
      title: {
        zh: '产品费用管理',
        en: 'productCost',
      },
    },
    component: () => import('src/views/productManagement/productCost'),
  },
  {
    path: '/productList',
    name: 'productList',
    meta: {
      title: {
        zh: '产品列表',
        en: 'productList',
      },
    },
    component: () => import('src/views/productManagement/productList'),
  },
  {
    path: '/scheduling',
    name: 'scheduling',
    meta: {
      title: {
        zh: '排班管理',
        en: 'scheduling',
      },
    },
    component: () => import('src/views/appointmentManagement/scheduling'),
  },
  {
    path: '/serveScope',
    name: 'serveScope',
    meta: {
      title: {
        zh: '服务范围管理',
        en: 'serveScope',
      },
    },
    component: () => import('src/views/appointmentManagement/serveScope'),
  },
  {
    path: '/referral',
    name: 'referral',
    meta: {
      title: {
        zh: '转诊服务',
        en: 'referral',
      },
    },
    component: () => import('src/views/appointmentManagement/referral'),
  },
  {
    path: '/appointmentRegister',
    name: 'appointmentRegister',
    meta: {
      title: {
        zh: '预约登记',
        en: 'appointmentRegister',
      },
    },
    component: () => import('src/views/appointmentManagement/appointmentRegister'),
  },
  {
    path: '/projectManagement',
    name: 'projectManagement',
    meta: {
      title: {
        zh: '方案管理',
        en: 'projectManagement',
      },
    },
    component: () => import('src/views/appointmentManagement/projectManagement'),
  },{
    path: '/miniProSentBack',
    name: 'miniProSentBack',
    meta: {
      title: {
        zh: '小程序采样寄回',
        en: 'miniProSentBack',
      },
    },
    component: () => import('src/views/appointmentManagement/miniProSentBack'),

  },
  {
    path: '/infomationrelease',
    name: 'infomationrelease',
    meta: {
      title: {
        zh: '资讯发布管理',
        en: 'infomationrelease',
      },
    },
    component: () => import('src/views/infomationManagement/infomationrelease'),
  },
  {
    path: '/formAndVideo',
    name: 'formAndVideo',
    meta: {
      title: {
        zh: '视频表单管理',
        en: 'formAndVideo',
      },
    },
    component: () => import('src/views/infomationManagement/formAndVideo'),
  },
  {
    path: '/infomationType',
    name: 'infomationType',
    meta: {
      title: {
        zh: '资讯类型管理',
        en: 'infomationType',
      },
    },
    component: () => import('src/views/infomationManagement/infomationType'),
  },
  {
    path: '/orderManagement',
    name: 'orderManagement',
    meta: {
      title: {
        zh: '订单管理',
        en: 'orderManagement',
      },
    },
    component: () => import('src/views/orderPurse/orderManagement'),
  },
  {
    path: '/wallet',
    name: 'wallet',
    meta: {
      title: {
        zh: '钱包充值',
        en: 'wallet',
      },
    },
    component: () => import('src/views/orderPurse/wallet'),
  },
  {
    path: '/model',
    name: 'model',
    meta: {
      title: {
        zh: '通知模版',
        en: 'model',
      },
    },
    component: () => import('src/views/notice/model'),
  },
  {
    path: '/noticeManagement',
    name: 'noticeManagement',
    meta: {
      title: {
        zh: '通知管理',
        en: 'noticeManagement',
      },
    },
    component: () => import('src/views/notice/noticeManagement'),
  },
  {
    path: '/theDoor',
    name: 'theDoor',
    meta: {
      title: {
        zh: '上门任务',
        en: 'theDoor',
      },
    },
    component: () => import('src/views/appointmentManagement/theDoor'),
  },
  {
    path: '/mailTask',
    name: 'mailTask',
    meta: {
      title: {
        zh: '邮寄任务',
        en: 'mailTask',
      },
    },
    component: () => import('src/views/appointmentManagement/mailTask'),
  },
  // {
  //   path: '/medicalExaminationTasks',
  //   name: 'medicalExaminationTasks',
  //   meta: {
  //     title: {
  //       zh: '体检任务',
  //       en: 'medicalExaminationTasks',
  //     },
  //   },
  //   component: () => import('src/views/appointmentManagement/medicalExaminationTasks'),
  // },
  {
    path: '/visit',
    name: 'visit',
    meta: {
      title: {
        zh: '到访任务',
        en: 'visit',
      },
    },
    component: () => import('src/views/appointmentManagement/visit'),
  },
  {
    path: '/externalEquity',
    name: 'externalEquity',
    meta: {
      title: {
        zh: '外部权益处理',
        en: 'externalEquity',
      },
    },
    component: () => import('src/views/appointmentManagement/externalEquity'),
  },
  {
    path: '/online',
    name: 'online',
    meta: {
      title: {
        zh: '在线咨询',
        en: 'online',
      },
    },
    component: () => import('src/views/online/online'),
  },
  {
    path: '/questionTemplate',
    name: 'questionTemplate',
    meta: {
      title: {
        zh: '问卷模板',
        en: 'questionTemplate',
      },
    },
    component: () => import('src/views/questionTemplate'),
  },
  {
    path: '/satisfactionquestion',
    name: 'satisfactionquestion',
    meta: {
      title: {
        zh: '满意度调查问卷',
        en: 'satisfactionquestion',
      },
    },
    component: () => import('src/views/questionsurvey/satisfactionquestion'),
  },
]
