import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './language/index'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import Fragment from 'vue-fragment'
import './styles/theme.scss'
import './styles/index.scss'
import 'nprogress/nprogress.css' // progress bar style
import './styles/util.scss'
import * as echarts from 'echarts';

import VueQuillEditor from 'vue-quill-editor'
//引入富文本css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import AMap from 'vue-amap';

// 【语言】设置element-ui语言
Vue.use(ElementUI, {
	i18n: (key, value) => {
		return i18n.t(key, value)
	},
	size: 'small',
})
Vue.use(Fragment.Plugin)
Vue.use(VueQuillEditor)
Vue.use(AMap)
AMap.initAMapApiLoader({
	// 高德key
	key: 'cdfc89b6c4e8cbeab80b7f81060df195', // 自己到官网申请，我随便写的
	// 插件集合 （插件按需引入）
	plugin: ['AMap.Autocomplete', 'AMap.DistrictSearch', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView',
		'AMap.ToolBar', 'AMap.MapType',
		'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MarkerClusterer'
	],
	v: '1.4.15', // 我也不知道为什么要写这个，不写项目会报错，而且我随便写的，跟我下载的版本对应不了
	uiVersion: '1.0.11' // ui版本号，也是需要写
})
let _ = require('lodash')
Vue.prototype._ = _
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
const BrowserLogger = require('alife-logger');
const __bl = BrowserLogger.singleton({
	pid: "hsbp9owxd5@4c7af4ca50c2873",
	appType: "web",
	imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
	sendResource: true,
	enableLinkTrace: true,
	behavior: true
});
const vm = new Vue({
	i18n,
	router,
	store,
	render: h => h(App),
}).$mount('#app')
export default vm
